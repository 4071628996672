<template>
  <div id="begins">
    <div class="left">
      <div class="title">
        <img
          src="../../img/板块图标 - 副本_slices/icon.png"
          style="width: 0.18rem"
          alt=""
        />
        <span>课程列表</span>
      </div>
      <!-- 无课程时显示 -->
      <div class="box" v-if="course.length == 0">
        <img
          src="../../img/上课/暂无数据 (1).png"
          alt=""
          style="width: 113px; height: 61px"
        />
        <div class="txt">当前暂无创建课程~</div>
      </div>
      <ul class="classList">
        <li
          @click="
            num = index;
            getId(item.id);
          "
          v-for="(item, index) in course"
          :key="index"
        >
          <!-- <div class="text"> -->
          <div class="line" :class="{ active: num == index }"></div>
          <span>{{ item.course_name }}</span
          ><el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 50px"
            >
              <img
                class="more"
                src="../../img/板块图标 - 副本_slices/更  多 (1) 拷贝 6.png"
                style="width: 20/96in"
                alt=""
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="courseedit(item.id)">编辑课程</span>
                <img
                  src="../../img/板块图标 - 副本_slices/编辑.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item>
              <!-- <el-dropdown-item
                >复制课程<img
                  src="../../img/板块图标 - 副本_slices/复制.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item> -->
              <el-dropdown-item>
                <span @click="coursedel(item.id)">删除课程</span>
                <img
                  src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item>
              <el-dropdown-item>
                <span @click="sectionadd(item.id)">添加课时</span>
                <img
                  src="../../img/板块图标 - 副本_slices/添加2.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- </div> -->
        </li>
      </ul>
      <div class="add">
        <div class="addbtn" @click="addClasses">添加新课程</div>
      </div>
    </div>
    <div class="not-img" v-if="section.length == 0">
      <img src="../../img/板块图标 - 副本_slices/04_无数据.png" alt="" />
      <div class="txt">您还未发布任何授课场景~</div>
    </div>
    <ul class="middle">
      <li v-for="(item, index) in section" :key="index">
        <div class="img">
          <img :src="item.cover" alt="" />
        </div>
        <div class="info">
          <div class="title">{{ item.course_section_name }}</div>
          <ul>
            <li @click="getTid(item.id)">编辑场景</li>
            <li @click="del(item.id)">删除</li>
            <li
              @click="
                showcourse = true;
                getList(item.id);
              "
            >
              预览
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="right">
      <div class="title">课程信息</div>
      <ul>
        <li>
          课程名称：<span>{{ info.course_name }}</span>
        </li>
        <li>
          课时总量：<span>{{ info.course_time }}</span>
        </li>
        <!-- <li>课程类型：<span></span></li> -->
        <li>
          创建时间：<span>{{ info.create_time }}</span>
        </li>
      </ul>
    </div>
    <el-dialog
      title="预览场景资源"
      :visible.sync="showcourse"
      class="addCourse"
    >
      <div class="box">
        <div class="leftfile">
          <ul class="classLists">
            <li v-for="(item, index) in list" :key="index">
              <div class="head">
                <img
                  class="line"
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(3).png"
                  alt=""
                />
                <span>{{ item.catalog_name }}</span>
              </div>
              <ul
                class="smallList"
                v-for="(item1, index1) in item.catalog"
                :key="index1"
              >
                <li
                  @click="
                    nums = index + String(index1);
                    getcatalogFile(item1.id);
                  "
                >
                  <img
                    class="left_img"
                    src="../../img/板块图标 - 副本_slices/矢量智能对象.png"
                    alt=""
                  />
                  <span>{{ item1.catalog_name }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="rightfile">
          <ul>
            <li v-for="(item, v) in fileList" :key="v">
              <div class="img">
                <img
                  v-if="item.file_type == 1"
                  src="../../img/板块图标 - 副本_slices/ppt 拷贝.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 2"
                  src="../../img/板块图标 - 副本_slices/word (3) 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 3"
                  src="../../img/板块图标 - 副本_slices/视频 (2) 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 4"
                  src="../../img/板块图标 - 副本_slices/音频 (1) 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 5"
                  src="../../img/板块图标 - 副本_slices/图片 (3) 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 6"
                  src="../../img/板块图标 - 副本_slices/？ 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 7"
                  src="../../img/板块图标 - 副本_slices/三维分析 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 8"
                  src="../../img/板块图标 - 副本_slices/椭圆 2 拷贝 2.png"
                  alt=""
                />
                <img
                  v-else-if="item.file_type == 9"
                  src="../../img/板块图标 - 副本_slices/360OnlinVote (1) 拷贝 2.png"
                  alt=""
                />
              </div>
              <div class="filename">
                <span
                  v-if="item.file_type == 1"
                  @click="
                    dia1 = true;
                    looks1(item.id);
                  "
                  >PPT资源展示</span
                >
                <span
                  v-else-if="item.file_type == 2"
                  @click="
                    dia2 = true;
                    looks2(item.id);
                  "
                  >文档资源展示</span
                >
                <span
                  v-else-if="item.file_type == 3"
                  @click="
                    dia3 = true;
                    looks3(item.id);
                  "
                  >视频资源展示</span
                >
                <span
                  v-else-if="item.file_type == 4"
                  @click="
                    dia4 = true;
                    looks4(item.id);
                  "
                  >音频资源展示</span
                >
                <span
                  v-else-if="item.file_type == 5"
                  @click="
                    dia5 = true;
                    looks5(item.id);
                  "
                  >图文资源展示</span
                >

                <span
                  v-else-if="item.file_type == 6"
                  @click="
                    dia6 = true;
                    looks6(item.id);
                  "
                  >问卷资源展示</span
                >
                <span
                  v-else-if="item.file_type == 7"
                  @click="
                    dia7 = true;
                    looks7(item.id);
                  "
                  >模型资源展示</span
                >
                <span
                  v-else-if="item.file_type == 8"
                  @click="
                    dia8 = true;
                    looks8(item.id);
                  "
                  >试题资源展示</span
                >
                <span
                  v-else
                  @click="
                    dia9 = true;
                    looks9(item.id);
                  "
                  >实景资源展示</span
                >
              </div>
              <p>{{ item.file_name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="PPT资源预览" :visible.sync="dia1" top="3vh">
      <div class="box">
        <iframe
          id="iframe1"
          width="1424"
          height="754"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="ppt"
        ></iframe>
        <!-- https://view.officeapps.live.com/op/view.aspx?src=http://ke.yunlearning.com/c.pptx -->
      </div>
    </el-dialog>
    <el-dialog title="文档资源预览" :visible.sync="dia2">
      <div class="box">
        <iframe
          id="iframe1"
          width="100%"
          height="750px"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="word"
        ></iframe>
      </div>
    </el-dialog>
    <el-dialog title="视频资源预览" :visible.sync="dia3" @close="videoclose">
      <div class="box">
        <video-player
          class="vjs-custom-skin"
          :options="playerOptions"
        ></video-player>
      </div>
    </el-dialog>
    <el-dialog
      title="音频资源预览"
      :visible.sync="dia4"
      class="dia4"
      @close="musicclose"
    >
      <div class="box">
        <div class="main">
          <span>{{ musicname }}</span>
          <div class="pic">
            <img src="../../img/上课/音线.jpg" alt="" />
            <img src="../../img/上课/音线.jpg" alt="" />
          </div>
          <div class="roll">
            <div class="img">
              <img src="../../img/上课/黑胶.png" alt="" />
            </div>
            <div class="btnimg" @click="control">
              <img
                v-if="musicbtn == 0"
                class="stop"
                src="../../img/上课/暂停.png"
                alt=""
              />
              <img v-else class="play" src="../../img/上课/播放.png" alt="" />
            </div>
          </div>
          <div class="music">
            <audio
              id="audio"
              controls
              loop
              @click="control"
              controlsList="nodownload"
              :src="music"
            ></audio>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="图文资源预览" :visible.sync="dia5" class="dia5">
      <div class="box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(img, index) in banners"
              :key="index"
            >
              <img :src="img" alt />
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <!-- <div class="swiper-pagination"></div> -->
          <div class="swiper-button-prev"></div>
          <div class="title" v-html="imgname"></div>
        </div>
        <div class="smallbox">
          <div class="smallimg">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(img, index) in banners"
                :key="index + '1'"
                @click="to(index + 1)"
              >
                <img :src="img" alt />
              </div>
            </div>
          </div>

          <div class="swiper-button-next s1"></div>
          <!-- <div class="swiper-pagination1"></div> -->
          <div class="swiper-button-prev s2"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="问卷资源预览" :visible.sync="dia6" class="dia6">
      <div class="box">
        <ul class="topiclists">
          <li v-for="(item, index) in ques" :key="index">
            <div class="top">
              <div class="title">
                <b>{{ index + 1 }}</b
                >. <span v-show="item.type == 0">选择题</span
                ><span v-show="item.type != 0">判断题</span>

                <div class="titletopic" v-html="item.question"></div>
              </div>
              <ul>
                <li>
                  <div>A</div>
                  <span v-html="item.a"> </span>
                </li>
                <li>
                  <div>B</div>
                  <span v-html="item.b"> </span>
                </li>
                <li>
                  <div>C</div>
                  <span v-html="item.c"> </span>
                </li>
                <li>
                  <div>D</div>
                  <span v-html="item.d"></span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog title="试题资源预览" :visible.sync="dia8" class="dia8">
      <div class="box">
        <ul class="topiclists">
          <li v-for="(item, index) in topic" :key="index">
            <div class="top">
              <div class="title">
                <b>{{ index + 1 }}</b
                >. <span v-show="item.type == 0">选择题</span
                ><span v-show="item.type != 0">判断题</span>

                <div class="titletopic" v-html="item.question"></div>
              </div>
              <ul>
                <li>
                  <div>A</div>
                  <span v-html="item.a"> </span>
                </li>
                <li>
                  <div>B</div>
                  <span v-html="item.b"> </span>
                </li>
                <li>
                  <div>C</div>
                  <span v-html="item.c"> </span>
                </li>
                <li>
                  <div>D</div>
                  <span v-html="item.d"></span>
                </li>
              </ul>
            </div>
            <div class="bottom">
              <div class="title">
                <div class="txt">
                  正确答案：<span v-html="item.answer"></span>
                </div>
              </div>
              <div class="info">解析：<span v-html="item.analysis"></span></div>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog title="实景资源预览" :visible.sync="dia9" class="dia9">
      <div class="box">
        <Three1 v-bind:real="real" />
        <!-- <iframe src="" frameborder="0"></iframe> -->
      </div>
    </el-dialog>
    <el-dialog title="模型资源预览" :visible.sync="dia7" class="dia7">
      <div class="box" style="display: flex; justify-content: center">
        <iframe
          id="iframe1"
          width="1424"
          height="754"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="model"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import $ from "jquery";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import Swiper from "swiper";
import Three1 from "../../components/attend/Three1.vue";
var swiper;
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      num: 0,
      nums: 0,
      course: "",
      section: "",
      info: "",
      cid: "",
      showcourse: false,
      list: "",
      fileList: "",

      dia1: false,
      ppt: "",
      dia2: false,
      word: "",
      dia3: false,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

        autoplay: false, //如果true,浏览器准备好时开始回放。

        controls: true, //控制条

        preload: "auto", //视频预加载

        muted: false, //默认情况下将会消除任何音频。

        loop: false, //导致视频一结束就重新开始。

        language: "zh-CN",

        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        sources: [
          {
            type: "video/mp4",

            src: require("../../assets/薛之谦-演员(标清).mp4"),

            // src: "",

            //src: 'src/views/sjsc/sjsc01_carInfoManage/2.mp4'//你所放置的视频的地址，最好是放在服务器上
          },
        ],

        //poster: "http://39.106.117.192:8080/static/indexImg.png", //你的封面地址（覆盖在视频上面的图片）

        width: document.documentElement.clientWidth,

        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      dia4: false,
      musicname: "",
      music: "",
      musicimgurl: "",
      musicbtn: 1,
      dia5: false,
      banners: [],
      imgname: "",
      dia6: false,
      ques: [],
      dia8: false,
      topic: [],
      dia7: false,
      model: "",
      dia9: false,
      real: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/course/index", {}, (res) => {
        console.log(res);
        if (res.code == 1) {
          this.course = res.data;
          this.getId(res.data[0].id);
        }
      });
    },
    getId(id) {
      util.post("/section/index", { cid: id }, (res) => {
        console.log(res);
        console.log(res.data.section);
        this.cid = res.data.id;
        this.section = res.data.section;
        this.info = res.data;
      });
    },
    getTid(tid) {
      // console.log(tid);
      // util.post("/catalog", { csid: tid }, (res) => {
      //   console.log(res);
      //   if (res.code == 1) {
      this.$router.push({ path: "/main/addscene", query: { csid: tid } });
      // }
      // });
    },
    // 编辑课程
    courseedit(id) {
      this.$router.push({
        path: "/main/editclass",
        query: { id: id },
      });
    },
    // 删除课程
    coursedel(id) {
      util.post("/course/del", { id: id }, (res) => {
        if (res.code == 1) {
          util.post("/course/index", {}, (res) => {
            if (res.code == 1) {
              console.log(res);
              this.course = res.data;
              if (res.data.length != 0) this.getId(res.data[0].id);
              else this.section = "";
            }
          });
        }
      });
    },
    del(id) {
      // if (res.code == 1) {
      // }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          util.post("/course/section_del", { id: id }, (res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            util.post("/section/index", { cid: this.cid }, (res) => {
              this.section = res.data.section;
              this.info = res.data;
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sectionadd(id) {
      this.$router.push({
        path: "/main/sectionadd",
        query: { cid: id },
      });
    },
    addClasses() {
      this.$router.push("/main/addclasses");
    },
    getList(id) {
      util.post("/catalog", { csid: id }, (res) => {
        // console.log(res.data);
        this.list = res.data;
        this.csid = this.$route.query.csid;
        this.getcatalogFile(res.data[0].catalog[0].id);
      });
    },
    getcatalogFile(id) {
      util.post("/catalogFile", { catalog_id: id }, (res) => {
        this.cid = id;
        console.log(res);
        if (res.code == 1) {
          this.fileList = res.data;
        }
      });
    },
    // ppt预览
    looks1(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        this.ppt =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          "http://zy.yunlearning.com" +
          res.data.file_path;
        // this.imgurl = res.data.cover;
        console.log(res);
      });
    },
    // 文档预览
    looks2(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        this.word =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          "http://zy.yunlearning.com" +
          res.data.file_path;
      });
    },
    // 视频预览
    looks3(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.playerOptions.sources[0].src = res.data.file_path;
      });
    },
    // videoclose(action, instance, done) {
    //   console.log(action, instance, done);
    // },
    // 音频预览
    looks4(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.music = res.data.file_path;
        this.musicname = res.data.file_name;
        this.musicinfo();
      });
    },
    videoclose() {
      this.playerOptions.sources[0].src = "";
    },
    musicclose() {
      this.music = "";
    },
    musicinfo() {
      var audio = document.getElementById("audio");

      // console.log(audio.duration);
      if (audio.paused) {
        $(".pic img").css({
          webkitAnimationPlayState: "paused",
        });
        $(".roll .img img").css({
          webkitAnimationPlayState: "paused",
        });
      } else {
        $(".pic img").css({
          webkitAnimationPlayState: "running",
        });
        $(".roll .img img").css({
          webkitAnimationPlayState: "running",
        });
      }
    },
    control() {
      var audio = document.getElementById("audio");
      if (audio !== null) {
        if (audio.paused) {
          audio.play(); // 播放
          this.musicbtn = 0;
          $(".pic img").css({
            webkitAnimationPlayState: "running",
          });
          $(".roll .img img").css({
            webkitAnimationPlayState: "running",
          });
        } else {
          audio.pause(); // 暂停
          this.musicbtn = 1;

          $(".pic img").css({
            webkitAnimationPlayState: "paused",
          });
          $(".roll .img img").css({
            webkitAnimationPlayState: "paused",
          });
        }
      }
    },
    // 图文预览
    looks5(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.banners = res.data.file_path.split(",");
        this.imgname = res.data.content;

        this.$nextTick(() => {
          swiper = new Swiper(".swiper-container", {
            // autoplay: {
            //   delay: 1000, //1秒切换一次
            // },

            loop: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // pagination: {
            //   el: ".swiper-pagination",
            //   clickable: true,
            // },
          });
        });
        this.$nextTick(() => {
          new Swiper(".smallimg", {
            loop: false,
            slidesPerView: 5,
            navigation: {
              nextEl: ".s1",
              prevEl: ".s2",
            },
          });
        });
      });
    },
    to(i) {
      console.log(i);
      swiper.slideTo(i, 500, false);
    },
    // 问卷预览
    looks6(id) {
      util.post("/questionnaireList", { catalog_file_id: id }, (res) => {
        console.log(res);
        this.ques = res.data;
      });
    },
    // 试题
    looks8(id) {
      util.post("/questionList", { catalog_file_id: id }, (res) => {
        console.log(res);
        this.topic = res.data;
      });
    },
    // 模型
    looks7(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);

        let obj = JSON.parse(res.data.file_path);
        // http://192.168.1.210:8093
        this.model =
          `/threejs/a.html?names=` + obj.file_name + "&" + "path=" + obj.path;
      });
    },
    looks9(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.real = res.data.file_path;
      });
    },
  },
  components: {
    Three1,
  },
};
</script>
<style lang="less" scoped>
#begins {
  height: 100%;
  display: flex;

  .left {
    width: 2.38rem /* 238/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 82px;
      img {
        width: 36px;
        height: 45px;
      }
      .txt {
        font-size: 14px;
        font-weight: 400;
        color: #8a8e99;
        margin-top: 19px;
      }
    }
    .title {
      height: 0.68rem /* 68/100 */;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      span {
        font-size: 0.2rem /* 20/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .classList {
      font-size: 0.14rem /* 14/100 */;
      font-weight: 500;
      color: #000000;
      line-height: 0.63rem /* 63/100 */;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.17rem /* 17/100 */;
        cursor: pointer;
        // .text {
        //   display: flex;
        //   align-items: center;

        span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        // }
        .line {
          width: 0.05rem /* 5/100 */;
          height: 0.23rem /* 23/100 */;
          margin-right: 0.32rem /* 32/100 */;
        }
      }
    }
    .add {
      display: flex;
      justify-content: center;
      margin-top: 1.2rem /* 120/100 */;
      .addbtn {
        width: 1.4rem /* 140/100 */;
        height: 0.45rem /* 45/100 */;
        background-color: #1c5eff;
        border-radius: 0.05rem /* 5/100 */;
        color: #fcfcfc;
        font-size: 0.15rem /* 15/100 */;
        line-height: 0.45rem /* 45/100 */;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .middle {
    width: calc(100% - 2.38rem - 2.79rem);
    height: 100%;
    padding: 0.38rem /* 38/100 */ 0.2rem /* 20/100 */ 0.38rem 0.47rem
      /* 47/100 */;
    // display: flex;
    // flex-wrap: wrap;
    > li {
      float: left;
      width: 2.6rem /* 260/100 */;
      height: 2.06rem /* 206/100 */;
      border-radius: 0.05rem /* 5/100 */;
      background-color: #fff;
      margin-right: 0.54rem /* 54/100 */;
      margin-bottom: 0.38rem /* 38/100 */;
      font-size: 0.14rem /* 14/100 */;
      .img {
        width: 2.6rem /* 260/100 */;
        height: 1.45rem /* 145/100 */;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .info {
        height: 0.77rem /* 77/100 */;
        padding: 0 0.14rem /* 14/100 */ 0.1rem /* 10/100 */;
        .title {
          line-height: 0.3rem /* 30/100 */;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        ul {
          display: flex;
          justify-content: flex-end;
          margin-top: 0.04rem /* 4/100 */;
          li {
            margin-left: 0.12rem /* 12/100 */;
            cursor: pointer;
          }
        }
      }
    }
  }
  .right {
    width: 2.79rem /* 279/100 */;
    height: 100%;
    border-left: 1px solid #cdcdcd;
    .title {
      font-size: 0.16rem /* 16/100 */;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 0.63rem /* 63/100 */;
      background-color: #dddfe2;
      padding-left: 0.21rem /* 21/100 */;
    }
    ul {
      padding: 0 0.2rem /* 20/100 */;
      li {
        line-height: 0.63rem /* 63/100 */;
        font-size: 0.14rem /* 14/100 */;
        font-weight: 400;
        color: #4e4e4e;
      }
    }
  }
}
.not-img {
  width: 413px;
  position: absolute;
  top: 370px;
  left: 794px;
  img {
    width: 100%;
  }
  .txt {
    font-size: 14px;
    font-weight: 400;
    color: #b8b8b8;
    line-height: 16px;
    text-align: center;
  }
}
@media screen and (max-width: 1280/96in) {
  .functions {
    width: 51.19/96in !important;
  }
  .mains {
    .left {
      width: 158.66/96in !important;
      .title {
        width: 156.66/96in;
      }
      .classList {
        .more {
          width: 13.33/96in !important;
        }
        .li {
          .text {
            width: 108.66/96in !important;
          }
        }
      }
    }
    .right {
      width: 186/96in !important;
    }
  }
  .middle {
    > li {
      width: 173.33/96in !important;
      height: 137.33/96in !important;
      .img {
        width: 173.33/96in !important;
        height: 97.5/96in !important;
      }
      .info {
        .title {
          line-height: 20/96in !important;
        }
      }
    }
  }
}
.el-dropdown-menu {
  box-shadow: 0px 4px 23px 0px rgba(28, 94, 255, 0.1);
  font-size: 0.14rem /* 14/100 */;
  color: #575759;
}
.active {
  background-color: #1c5eff;
}
.addCourse {
  /deep/.el-dialog {
    width: 1600px !important;
    // height: 900px !important;
    // margin: 0 auto !important;
    // margin-top: 22.5vh !important;
  }
  /deep/.el-dialog__body {
    height: calc(100% - 0.69rem) !important;
    padding: 35px 70px;
    .box {
      width: 100%;
      height: 100%;
      background-color: #eff0f2;
      display: flex;
      // input {
      //   margin-top: 20px;
      // }
      // .addcourseBtn {
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-around;
      //   margin-top: 100px;
      // }
      .leftfile {
        width: 2.38rem /* 238/100 */;
        height: 100%;
        border: 1px solid #cdcdcd;
        border-top: none;
        border-bottom: none;
        > li {
          display: inline-block;
        }
        .classLists {
          width: 2.38rem;
          font-size: 0.14rem /* 14/100 */;
          font-weight: 500;
          color: #000000;
          line-height: 0.63rem /* 63/100 */;

          .head {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-right: 0.17rem /* 17/100 */;
            cursor: pointer;
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              color: #131313;
              font-size: 18px;
              font-weight: bold;
            }
            .line {
              width: 18px;
              margin: 0 7px 0 8px;
            }
          }
          .smallList {
            .active {
              font-weight: bold;
            }
            li:hover {
              font-weight: bold;
            }
            li {
              display: flex;
              align-items: center;
              padding: 0 15px 0 33px;
              line-height: 40px;
              cursor: pointer;
              .left_img {
                width: 16px;
                margin-right: 6px;
              }
              span {
                width: 100%;
                font-size: 14px;
                color: #131313;
              }
              .right_img {
                // width: 36/96in;
                display: flex;
                img:first-child {
                  margin-right: 9px;
                }
              }
            }
          }
        }
      }
      .rightfile {
        ul {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 10px;
          li {
            width: 150px;
            height: 150px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 23px;
            .img {
              width: 54px;
              height: 68px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 15px 0 10px;
            }
            img {
              width: 54px;
            }
            .filename {
              font-size: 12px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            p {
              color: #d0d0d0;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
// /deep/.el-dialog::
/deep/.el-dialog {
  width: 1600px !important;
  height: 900px !important;
  // overflow: auto;
  // margin-top: 7.5vh;
  // height: 900px !important;
  // margin: 0 auto !important;
  // margin-top: 22.5vh !important;
  .box::-webkit-scrollbar {
    width: 0;
  }
  .box {
    height: 750px;
    overflow: auto;
  }
}
/deep/.el-dialog__header {
  text-align: center;
  color: #2c2c2c;
  font-weight: bold;
  padding-top: 35/96in;
}
.dia4 {
  .box {
    position: relative;
  }
  .main {
    width: 91%;
    height: 744px;
    // background: url();
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, 9%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    overflow: hidden;
    background: #2d3b5c;
    span {
      display: inline-block;
      width: 100%;
      height: 130px;
      background-color: rgba(0, 0, 0, 0.8);
      text-align: left;
      color: #fff;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
      padding: 31px 0 0 61px;
      font-size: 16px;
      font-weight: bold;
    }
    .pic {
      width: 1164px;
      height: 257px;
      background-color: #1c7cff;
      border-radius: 20px;
      display: flex;
      overflow: hidden;
      position: relative;
      img {
        height: 100%;
        animation: myimg 11s linear infinite;
      }
      @keyframes myimg {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-1211.56px);
        }
      }
    }
    .roll {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%);
      > .img {
        > img {
          width: 410px;
          animation: rollimg 6s linear infinite;
        }
      }
      .btnimg {
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @keyframes rollimg {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .pause {
        animation-play-state: paused;
      }
    }
    audio {
      z-index: 9;
      width: 100%;
      border-radius: 0 !important;
    }
    .music {
      width: 100%;
      height: 130px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    }
    .img1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      z-index: -1;
    }
  }
}
.dia5 {
  .box {
    .swiper-container {
      width: 1024px !important;
      height: 576px;
      overflow: hidden;
      .swiper-slide {
        img {
          width: 100%;
        }
      }
      .title {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 169px;
        padding: 66px 13px 0 15px;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(0, 0, 0, 1)
        );
        line-height: 25px;
        font-size: 14px;
        color: #fff;
        z-index: 1;
      }
    }
    .smallbox {
      display: flex;
      justify-content: center;
      position: relative;
      .smallimg {
        margin-top: 37px;
        width: 1024px !important;
        height: 112px;
        overflow: hidden;
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 168px;
          }
        }
      }
      .s1,
      .s2 {
        top: 66%;
      }
      .s1 {
        right: 93px;
      }
      .s2 {
        left: 93px;
      }
    }
  }
}
.dia6,
.dia8 {
  .topiclists {
    margin-right: 40px;
    > li {
      background-color: #fff;
      padding: 48px 131px 56px 74px;

      margin-bottom: 5px;
      .top {
        .title {
          font-weight: 400;
          color: #2c2c2c;
          b {
            font-weight: 400;
          }
          .titletopic {
            display: inline-block;

            /deep/img {
              width: 100px !important;
              vertical-align: top;
              margin: 0 2px;
            }
          }

          span {
            display: inline-block;
            width: 56px;
            height: 22px;
            line-height: 22px;
            background: #b3c9ff;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            color: #2769e1;
            text-align: center;
          }
        }
        ul {
          // margin-bottom: 61px;
          li {
            display: flex;
            align-items: center;
            margin-top: 23px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
              width: 28px;
              height: 28px;
              border: 1px solid #cdcdcd;
              border-radius: 50%;
            }
            /deep/p {
              display: flex;
              align-items: center;
            }
            /deep/img {
              width: 100px !important;
              vertical-align: top;
              margin: 0 2px;
            }
          }
        }
      }
      .bottom {
        //   margin-top: 34px;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .txt {
            margin-top: 23px;
            color: #000;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .info {
          font-size: 16px;
          color: #2c2c2c;
          line-height: 36px;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}
.dia9 {
  .box {
    overflow: hidden;
  }
}
// .addCourse {
//   /deep/.el-dialog {
//     width: 394/96in !important;
//     height: 414/96in !important;
//     // margin: 0 auto !important;
//     // margin-top: 22.5vh !important;
//   }
//   /deep/.el-dialog__body {
//     height: calc(100% - 0.69rem) !important;
//     .box {
//       width: 100%;
//       height: 100%;
//       input {
//         margin-top: 20px;
//       }
//       .addcourseBtn {
//         display: flex;
//         align-items: center;
//         justify-content: space-around;
//         margin-top: 100px;
//       }
//     }
//   }
// }
</style>